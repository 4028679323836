import React, { useCallback, useState, useEffect } from "react";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CustomText, Popup, PrimaryCta } from "../../common/core_component";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { Loader } from "../Loader";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useDispatch } from "react-redux";
import { setLocalUserData } from "../../common/storage/storage";
import * as Action from "../../redux/actions";
import ReactPullToRefresh from "react-pull-to-refresh";
import { Sidebar } from "../../common/core_component";
import Footer from "../common/Footer";
import Header from "../header";

function SettingScreen() {
  const [loading, setLoading] = useState(false);
  const [isModalLogoutOpen, setModalLogoutOpen] = useState(false);
  const [isModalDeleteOpen, setisModalDeleteOpen] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ------- Detele Account Api -----------

  function deleteUser() {
    const params = {};
    Sdk.deleteUser(
      deleteUserSuccess,
      deleteUserFailed,
      (status) => setEffectiveLoad(status),
      params
      // authToken,
    );
  }
  function deleteUserSuccess(response) {
    // setAuthToken('');
    setLocalUserData();
    refreshUserData();
    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: "",
    });
    setLocalUserData();
    navigate("/");
    enqueueSnackbar("Account Delete", { variant: "success" });
  }

  function deleteUserFailed(errorList) {
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // ------- logout API ---------

  function logoutUser() {
    const params = {};
    Sdk.logoutUser(
      LogoutUserSuccess,
      logoutUserFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function LogoutUserSuccess(response) {
    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: "",
    });
    setLocalUserData();
    navigate("/");
    enqueueSnackbar("LoggedOut Successfully", { variant: "success" });
  }

  function logoutUserFailed(errorList) {
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function refreshUserData() {
    const params = {};
    Sdk.getInfluencerDataSelf(
      refreshUserDataSuccess,
      refreshUserDataFailed,
      updateProgress,
      params
    );
  }

  function refreshUserDataSuccess(userData) {
    // setLocalUserData(userData);
    // setInfluencerDetail(userData.influencer_deep_data?.influencer_data);
    // setCategoriesDetail(userData.influencer_deep_data?.categorys_data);
    // if (userData) {
    //   const { influencer_deep_data } = userData;
    //   dispatch({
    //     type: Action.UPDATE_USER_DATA,
    //     payload: influencer_deep_data,
    //   });
    // }
  }

  function refreshUserDataFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // Change navigation for all pages ---

  const closeLogoutModal = () => setModalLogoutOpen(false);
  const openLogoutModal = () => setModalLogoutOpen(true);
  const closeDeleteModal = () => setisModalDeleteOpen(false);
  const openDeleteModal = () => setisModalDeleteOpen(true);

  function renderEarnigs() {
    return (
      <>
        <div
          onClick={() => navigate("/earning")}
          className="white-card-bg flex flex-column  relative  m-t-8 justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Earnings"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }

  function rendertransaction() {
    return (
      <>
        <div
          onClick={() => navigate("/transaction")}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Transaction"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }

  function renderPrivacyPolicy() {
    return (
      <>
        <div
          onClick={() => window.open("https://getreelax.com/privacy-policy")}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Privacy Policy"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }

  function renderhelpAndSupport() {
    return (
      <>
        <div
          onClick={() => navigate("/help-support")}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Help and Support"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }
  function renderTerms() {
    return (
      <>
        <div
          onClick={() => window.open("https://getreelax.com/terms")}
          className="white-card-bg flex flex-column relative justify-center"
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Terms"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }

  function renderDeleleAccount() {
    return (
      <>
        <div
          onClick={openDeleteModal}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Delete Account"} className="red" />
          </div>
        </div>
      </>
    );
  }
  function renderLogout() {
    return (
      <>
        <div
          // ṇavigate to login screen ----
          onClick={() => setModalLogoutOpen(true)}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{
            minHeight: "40px",
            gap: "3px",
            cursor: "pointer ",
            borderRadius: "8px",
          }}>
          <div className="" style={{ paddingLeft: "10px" }}>
            <CustomText h5 text={"Logout"} className="red" />
          </div>
        </div>
      </>
    );
  }
  const logoutHeader = () => <div>Logout</div>;
  const renderBottomLogoutSheet = () => {
    return !isDesktop ? (
      <BottomSheet
        open={isModalLogoutOpen}
        onDismiss={closeLogoutModal}
        snapPoints={({ maxHeight }) => [0.35 * maxHeight, maxHeight]}
        style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}>
        <div
          className="m-t-20"
          style={{
            width: "90%",
            maxWidth: "786px",
            margin: "0 auto",
            padding: "20px",
          }}>
          <div className="text-align">
            <CustomText p text={"Are you sure to Logout?"} />
          </div>
          <div className="flex gap-10 m-t-20">
            <PrimaryCta
              className={"custom-button"}
              onClick={() => logoutUser()}
              isLoading={effectiveLoad}
              text={"Yes"}
            />
            <PrimaryCta
              className={"custom-button-bg-white"}
              onClick={closeLogoutModal}
              text={"No"}
              textColor={"white"}
            />
          </div>
        </div>
      </BottomSheet>
    ) : (
      <Popup
        isOpen={openLogoutModal}
        onClose={closeLogoutModal}
        headerText={"Logout"}
        width={"40%"}
        footerButton1Text={"No"}
        footerButton1Action={closeLogoutModal}
        footerButton2Text={"Yes"}
        footerButton2Action={() => logoutUser()}
        isLoading={effectiveLoad}>
        <div style={{ padding: 20 }}>
          <div className="text-align">
            <CustomText p text={"Are you sure to Logout?"} />
          </div>
        </div>
      </Popup>
    );
  };

  const renderBottomDeleteSheet = () => {
    return !isDesktop ? (
      <BottomSheet
        open={openDeleteModal}
        onDismiss={closeDeleteModal}
        snapPoints={({ maxHeight }) => [0.35 * maxHeight, maxHeight]}
        style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}>
        <div
          className="m-t-20"
          style={{
            width: "90%",
            maxWidth: "786px",
            margin: "0 auto",
            padding: "20px",
          }}>
          <div className="text-align">
            <CustomText p text={"Are you sure want to delete this account?"} />
          </div>
          <div className="flex gap-10 m-t-20">
            <PrimaryCta
              className={"custom-button"}
              onClick={() => deleteUser()}
              isLoading={effectiveLoad}
              text={"Yes"}
            />
            <PrimaryCta
              className={"custom-button-bg-white"}
              onClick={closeDeleteModal}
              // navigate to FAQ
              text={"No"}
              textColor={"white"}
            />
          </div>
        </div>
      </BottomSheet>
    ) : (
      <div>
        <Popup
          isOpen={openDeleteModal}
          onClose={closeDeleteModal}
          headerText={"Delete"}
          width={"40%"}
          footerButton1Text={"No"}
          footerButton1Action={closeDeleteModal}
          footerButton2Text={"Yes"}
          footerButton2Action={() => deleteUser()}
          isLoading={effectiveLoad}>
          <div style={{ padding: 20 }}>
            <div className="text-align">
              <CustomText
                p
                text={"Are you sure want to delete this account?"}
              />
            </div>
          </div>
        </Popup>
      </div>
    );
  };

  return (
    <div className="main-container">
      <div className="inner-main-container">
        
        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <>
            <div className="" style={{}}>
              <div className="container" style={{}}>
                {renderEarnigs()}
                {rendertransaction()}
                {renderPrivacyPolicy()}
                {renderhelpAndSupport()}
                {renderTerms()}
                {renderLogout()}
                {renderDeleleAccount()}
              </div>
            </div>

            {isModalLogoutOpen && renderBottomLogoutSheet()}
            {isModalDeleteOpen && renderBottomDeleteSheet()}
          </>
        )}
      </div>
    </div>
  );
}

export default SettingScreen;
