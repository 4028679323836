import React, { useEffect, useState } from 'react'
import Sdk from '../../common/sdk/influencerSdk';
import { CustomText, FallBack } from '../../common/core_component';
import moment from 'moment';
import svgs from '../../assets/icons/svgs';
import Icon from '../../assets/icons';
import { Loader } from '../Loader';
import { useNavigate } from 'react-router-dom';

function CreditHistory() {
  const [loading, setLoading] = useState(false);
  const [creditHistoryList, setCreditHistoryList] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    getCreditInfluencerList();
  }, []);

  function getCreditInfluencerList() {
    var params = {};
    Sdk.getCreditInfluencerList(
      getCreditInfluencerListSuccess,
      getCreditInfluencerListFailed,
      updateProgress,
      params,
    );
  }
  function getCreditInfluencerListSuccess(response) {
    console.log('get Credit list success', response.credit_list);
    setCreditHistoryList(response.credit_list)
  }

  console.log("credit", creditHistoryList);
  
  function getCreditInfluencerListFailed(error) {
    console.error('get Transaction List Failed', error);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function renderCreditHistory() {
    if (!creditHistoryList || creditHistoryList.length === 0) {
      return (
        <div className="fallback p-t-12">
          <FallBack
            icon={svgs.folder}
            heading="No Credit"
            title="No Credit Till now"
          />
        </div>
      );
    } else {
      let transactionText = '';
  
      return (
        <>
          <div className="white-card-bg m-t-8 flex flex-column justify-center">
            {creditHistoryList?.map((payout, index) => {
              if (payout?.credit_type === 'INFLUENCER_CREDIT_BID_CREATE_DEBIT') {
                transactionText = 'Credit debit';
              } else if (payout?.credit_type === 'INFLUENCER_CREDIT_PURCHASE_DEBIT') {
                transactionText = 'Credit purchase';
              }
  
              return (
                <React.Fragment key={index} >
                  <div className="flex justify-between m-t-8 m-b-8">
                    <div className="flex flex-column">
                      <CustomText
                        p
                        text={` ${transactionText} at ${
                          moment.unix(payout?.credit_date).format("DD MMM") || "N/A"
                        }`}
                        className="text-14"
                      />
                      <CustomText
                        p
                        text={`Transaction Id: #${
                          payout?.transaction_id?.toUpperCase() || "N/A"
                        }`}
                        className="text-14 m-t-2"
                      />
                    </div>
  
                    <div>
                      <CustomText
                        p
                        text={` Credits: ${payout?.credits || "-"}`}
                        className="green text-align m-t-4"
                      />
                    </div>
                  </div>
                  {index !== creditHistoryList.length - 1 && <hr className="dashed-line" />}

                </React.Fragment>
              );
            })}
          </div>
        </>
      );
    }
  }
  
  return (
      <div className="main-container">
      <div className="inner-main-container" style={{padding:0}}>

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div
          className=" "
          style={{minHeight : "86vh",}}
        >
          <div className="container">
            {renderCreditHistory()}</div>
        </div>
      )}
      </div>
      </div>
  );
}

export default CreditHistory
