import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import svgs from "../../assets/icons/svgs"; // Import SVG icons
import Icon from "../../assets/icons";
import reelaxLogo from "../../assets/images/Reelax.png";
import reelaxLogoFull from "../../assets/images/reelax-logo-dark-full.png";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname); // Track selected menu item

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSelected(location.pathname); // Update selected when route changes
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div
      className={`sidemenu`}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      {/* Show logo only if not on mobile */}
      {!isMobile && (
        <div className="reelax-img" onClick={() => handleNavigation("/")}>
          <img
            src={isHovered ? reelaxLogoFull : reelaxLogo}
            height={40}
            width={isHovered ? "auto" : 40}
            alt="Logo"
          />
        </div>
      )}

      {/* Navigation items */}
      <div className="sidemenu-items-container">
        <div
          className={`sidemenu-items ${selected === "/" ? "selected" : ""}`}
          onClick={() => handleNavigation("/")}
        >
          <div className="nav-icon">
            <Icon icon={svgs.homeIcon} />
          </div>
          <div className="nav-text">Home</div>
        </div>
        <div
          className={`sidemenu-items ${selected === "/bids" ? "selected" : ""}`}
          onClick={() => handleNavigation("/bids")}
        >
          <div className="nav-icon">
            <Icon icon={svgs.bidListIcon} />
          </div>
          <div className="nav-text">Bids</div>
        </div>
        <div
          className={`sidemenu-items ${selected === "/campaigns" ? "selected" : ""}`}
          onClick={() => handleNavigation("/campaigns")}
        >
          <div className="nav-icon">
            <Icon icon={svgs.explore} />
          </div>
          <div className="nav-text">Campaign</div>
        </div>
        <div
          className={`sidemenu-items ${selected === "/contracts" ? "selected" : ""}`}
          onClick={() => handleNavigation("/contracts")}
        >
          <div className="nav-icon">
            <Icon icon={svgs.assignmentCheck} />
          </div>
          <div className="nav-text">Assignment</div>
        </div>
        <div
          className={`sidemenu-items ${selected === "/profile" ? "selected" : ""}`}
          onClick={() => handleNavigation("/profile")}
        >
          <div className="nav-icon">
            <Icon icon={svgs.filledUser} />
          </div>
          <div className="nav-text">Profile</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
