import creators from "../assets/images/creators-right.png";
const RightMainContainer = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        overflowY: "auto",
        scrollbarWidth: "none",
        height: "100%",
      }}>
      <div
        className=""
        style={{ height: `calc(-60px + 100vh)`, maxHeight: 832,cursor:"pointer" }} onClick={()=>window.open("https://onelink.to/q2at8y", "_blank")}>
        <img src={creators} width="100%" height="100%" alt="Logo" />
      </div>
    </div>
  );
};
export default RightMainContainer;
