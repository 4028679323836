import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack"; // ✅ FIXED: Removed direct enqueueSnackbar import
import Sdk from "../../common/sdk/influencerSdk";
import {
  Core,
  CustomInputBox,
  CustomText,
  PrimaryCta,
  Sidebar,
} from "../../common/core_component";
import { BottomSheet } from "react-spring-bottom-sheet";
import logo from "../../assets/images/reelax-logo-dark-full.png";
import * as Action from "../../redux/actions";
import { useDispatch } from "react-redux";
import InfluencerDataScreen from "../InfluencerDataScreen";

function LoginOtpScreen({ isModalOpen, setModalOpen, CampaginModalOpen }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar(); // ✅ Using useSnackbar properly

  // State for login screen
  const [phoneNumber, setPhoneNumber] = useState("");
  const [influencer_data, setUserData] = useState(
    Core.Influencer.createInfluencerData()
  );
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [error_message, set_error_message] = useState(null);
  const [influencerData, setInfluencerData] = useState(null);
  const [vfyD, setVfyD] = useState({ cooldown_period: 45 });

  // State for OTP screen
  const [otp, setOTP] = useState("");
  const [seconds, setSeconds] = useState(vfyD.cooldown_period);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [openSignUpSidebar,setOpenSignUpSidebar]=useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const validatePhoneNumber = (phone) => {
    if (isNaN(phone) || phone.length !== 10) {
      set_error_message("Please enter a valid 10-digit phone number");
      return false;
    } else {
      set_error_message(null);
      return true;
    }
  };

  const updatePhoneNumberInInfluencerData = (phone) => {
    if (validatePhoneNumber(phone)) {
      const updatedInfluencerData = Core.Influencer.updateInfluencerPhoneNumber(
        influencer_data,
        phone
      );
      setUserData(updatedInfluencerData);
    }
  };

  const handleInputChange = (value) => {
    if (value.length <= 10) {
      setPhoneNumber(value);
      updatePhoneNumberInInfluencerData(value);
    }
  };

  // ------ API's ------
  function sendVerificationCode() {
    const updatedInfluencerData = {
      ...influencer_data,
      phone: String(phoneNumber),
    };

    var params = {
      influencer_data: JSON.stringify(updatedInfluencerData),
    };

    Sdk.sendVerificationCode(
      sendVerificationCodeSuccess,
      sendVerificationCodeFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function sendVerificationCodeSuccess(api_response) {
    const { influencer_data, success, vfy_d } = api_response;
    if (success) {
      setInfluencerData(influencer_data);
      setVfyD(vfy_d);
      setShowOtpScreen(true);
      setSeconds(vfy_d.cooldown_period);
      setResendEnabled(false);
    } else {
      enqueueSnackbar("Failed to send verification code", { variant: "error" });
    }
  }

  function sendVerificationCodeFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  const userNavigator = (influencerData) => {
    if (influencerData) {
      const { profile_status } = influencerData;

      if (profile_status === "NEW") {
        setOpenSignUpSidebar(true)
        return;
      }
      if (profile_status === "ACTIVE") {
        setModalOpen(false);
        if (CampaginModalOpen) {
          CampaginModalOpen(true);
        }
      }
    } 
  };

  function signInPublic() {
    if (!influencerData) {
      enqueueSnackbar("Influencer data is missing. Please try again.", {
        variant: "warning",
      });
      return;
    }

    const params = {
      influencer_data: JSON.stringify(influencerData),
      phone_verification_code: otp,
    };
    Sdk.signInPublic(
      signInPublicSuccess,
      signInPublicFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function signInPublicSuccess(userData) {
    if (userData) {
      const { influencer_deep_data } = userData;

      if (influencer_deep_data) {
        const { influencer_data } = influencer_deep_data;

        dispatch({
          type: Action.UPDATE_USER_DATA,
          payload: influencer_deep_data,
        });
        userNavigator(influencer_data);
        setOTP("");
      } else {
        userNavigator();
      }
    }
    // enqueueSnackbar("Login Successful", { variant: "success" });
  }

  function signInPublicFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  const resendOTP = () => {
    if (resendEnabled) {
      setResendEnabled(false);
      setSeconds(vfyD.cooldown_period);
      sendVerificationCode();
    }
  };

  const changeNumber = () => {
    setShowOtpScreen(false);
    setOTP("");
    setSeconds(vfyD.cooldown_period);
  };

  const handleOtpChange = (value) => {
    if (value.length <= 4) {
      setOTP(value);
    }
  };

  function renderAuthContent() {
    return (
      <div
        className="container"
        style={{
          padding: "20px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
          }}>
          <div>
            <CustomText p text="Login To" />
          </div>
          <div>
            <img src={logo} width={120} />
          </div>
        </div>

        {!showOtpScreen ? (
          <>
            <label>Phone Number</label>
            <div style={{ margin: "10px 0" }}>
              <CustomInputBox
                type="number"
                value={phoneNumber}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder="Enter your Number"
                className="m-t-10"
              />
            </div>
            <PrimaryCta
              onClick={sendVerificationCode}
              disabled={phoneNumber.length < 10}
              text="Send OTP"
              isLoading={effectiveLoad}
              className="custom-button m-t-10"
            />
          </>
        ) : (
          <>
            <p style={{ margin: "20px 0 10px 0 " }}>
              Mobile Number - {phoneNumber}
            </p>
            <CustomInputBox
              type="number"
              value={otp}
              onChange={(e) => handleOtpChange(e.target.value)}
              placeholder="Enter your OTP"
              maxLength={4}
              autoFocus
            />
            <PrimaryCta
              onClick={signInPublic}
              disabled={otp.length < 4}
              text="Verify"
              isLoading={effectiveLoad}
              className="custom-button m-t-10"
            />
            <div
              onClick={resendOTP}
              style={{
                cursor: resendEnabled ? "pointer" : "not-allowed",
                margin: "10px 0",
              }}>
              {resendEnabled ? "Re-Send OTP" : `Resend OTP in ${seconds}s`}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {isDesktop ? (
        <Sidebar
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          Header={() => <div>Login</div>}
          showFooter={false}
          sidebarWidth="400px">
          <div style={{ padding: "20px" }}>{renderAuthContent()}</div>
        </Sidebar>
      ) : (
        <BottomSheet
          open={isModalOpen}
          onDismiss={() => setModalOpen(false)}
          snapPoints={({ maxHeight }) => [0.75 * maxHeight, maxHeight]}
          style={{
            overflowY: "auto",
            zIndex: "1000",
            position: "absolute",
          }}>
          {renderAuthContent()}
        </BottomSheet>
      )}
  
      <InfluencerDataScreen
        openSignUpSidebar={openSignUpSidebar}
        setOpenSignUpSidebar={setOpenSignUpSidebar}
      />
    </div>
  );
  
}

export default LoginOtpScreen;
