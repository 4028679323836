import React, { useCallback, useState, useEffect } from "react";
import Footer from "../common/Footer";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import { Loader } from "../Loader";
import Active from "./Active";
import InActive from "./InActive";
import ReactPullToRefresh from "react-pull-to-refresh";
import svgs from "../../assets/icons/svgs";
import { Navigate, useNavigate } from "react-router-dom";
import Utils from "../../common/utils";
import LoginOtpScreen from "../common/LoginOtpScreen";
import Header from "../header";

function AllBids() {
  const [loading, setLoading] = useState();
  const [activeTab, setActiveTab] = useState("open");
  const [isLoginOtpSheet, setisLoginOtpSheet] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);

  const handleOpenClick = () => {
    setActiveTab("open");
  };

  const handleCloseClick = () => {
    setActiveTab("close");
  };

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { enqueueSnackbar } = useSnackbar();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  const navigate = useNavigate();

  const renderList = () => {
    if (activeTab === "open") {
      return <Active />;
    } else if (activeTab === "close") {
      return <InActive />;
    }
  };

  const openLoginOtp_Sheet = () => {
    setisLoginOtpSheet(true);
    // alert ("clicked!")
  };

  if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
    return (
      <div className="main-container">
        <div className="inner-main-container">
          <div className="justify-center flex-column" style={{ marginTop: 20 }}>
            <div className="fallback">
              <FallBack
                icon={svgs.reelaxLogo}
                heading="Please Login"
                title="For access to Proposals, please login to Reelax"
              />
              <div className="container page-center m-b-25">
                <PrimaryCta
                  className="custom-button"
                  onClick={() => openLoginOtp_Sheet()}
                  text={"Login"}
                />
              </div>
            </div>
          </div>
        </div>
        <LoginOtpScreen
          isModalOpen={isLoginOtpSheet}
          setModalOpen={setisLoginOtpSheet}
        />
      </div>
    );
  }

  return (
    <>
      <div className="main-container">
        <div className="inner-main-container">
          <div className="">
            <div className="popular-invited" style={{ paddingTop: "20px" }}>
              <div
                onClick={handleOpenClick}
                className={`tab ${activeTab === "open" ? "active-tab" : ""}`}>
                <CustomText
                  p
                  text={"OPENED"}
                  className="small-txt-pop-invited"
                />
              </div>

              <div
                onClick={handleCloseClick}
                className={`tab ${activeTab === "close" ? "active-tab" : ""}`}>
                <CustomText
                  p
                  text={"CLOSED"}
                  className="small-txt-pop-invited"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="" style={{ paddingTop: "90px" }}>
              <div
                className=""
                style={{ paddingBottom: "65px", marginTop: "9px" }}>
                {renderList()}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AllBids;
