import React, { useCallback, useEffect, useState } from "react";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { CustomText, FallBack } from "../../common/core_component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sdk from "../../common/sdk/influencerSdk";
import { Loader } from "../Loader";
import moment from "moment";
import ReactPullToRefresh from "react-pull-to-refresh";
import Footer from "../common/Footer";
import Header from "../header";

function Transaction() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [historyList, setHistoryList] = useState([]);
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true)
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   setLoading(false)
  // }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    getTransationList();
  }, []);

  function getTransationList() {
    var params = {};
    Sdk.getTransationList(
      getTransationListSuccess,
      getTransationListFailed,
      updateProgress,
      params
    );
  }
  function getTransationListSuccess(response) {
    console.log("get transaction list success", response);
    setHistoryList(response?.transaction_list);

    // setAppLoaded(true);
    // if (start == 0) {
    //   setHistoryList(response['transaction_list']);
    // } else {
    //   setHistoryList([...historyList, ...response['transaction_list']]);
    // }
    // setNext(response.pagination_data?.next);
  }
  console.log("list", historyList);

  function getTransationListFailed(error) {
    console.error("get Transation List Failed", error);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function rendertransactionList() {
    if (!historyList || historyList.length === 0) {
      return (
        <div className="fallback p-t-12">
          <FallBack
            icon={svgs.folder}
            heading="No Transactions"
            title="No Transactions found"
          />
        </div>
      );
    } else {
      let transactionText = "";
      if (historyList?.transaction_type === "INFLUENCER_SUBSCRIPTION_DEBIT") {
        transactionText = "Subscription purchase";
      } else if (
        historyList?.transaction_type === "INFLUENCER_CREDIT_PURCHASE_DEBIT"
      ) {
        transactionText = "Credit purchase";
      }

      return (
        <div className="container white-card-bg m-t-8 flex flex-column justify-center">
          {historyList?.map((list, index) => (
            <React.Fragment key={index}>
              <div className="flex justify-between m-t-8 m-b-8">
                <div className="flex flex-column">
                  <CustomText
                    p
                    text={` ${transactionText} at ${moment
                      .unix(list.transaction_date)
                      .format("h:mm a, DD MMM")}`}
                    className="text-14"
                  />
                  <CustomText
                    p
                    text={`Order: #${list?.order_id || "N/A"}`}
                    className="text-14"
                  />
                </div>

                <div>
                  <CustomText
                    p
                    text={`Rs. ${list?.transaction_amount || "-"}`}
                    className="green text-align m-t-4"
                  />
                </div>
              </div>
              {index !== historyList.length - 1 && (
                <hr className="dashed-line" />
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }
  }

  return (
    <div className="main-container">
      <div className="inner-main-container">
        <div className=" flex gap-10"></div>
        {loading ? (
          <div className="loader-mid">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div className="" style={{ paddingTop: "20px" }}>
            {rendertransactionList()}
          </div>
        )}
      </div>
    </div>
  );
}

export default Transaction;
