import React, { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import Sdk from "../../common/sdk/influencerSdk";
import { CustomText, FallBack, Popup, PrimaryCta } from "../../common/core_component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Loader } from "../Loader";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import svgs from "../../assets/icons/svgs";


function CreditBottom({ isModalOpen, setModalOpen }) {
  const [creditId, setCreditId] = useState("003");
  const [creditDataList, setCreditdataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [credit, setCredit] = useState(null);
  const navigate = useNavigate();
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const { enqueueSnackbar } = useSnackbar();

  const closeSendProposalModal = () => setModalOpen(false);

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("userData", userData);

  console.log("influn", influencer_data);
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
      getCreditList();
      getCreditDetails();
  }, []);

  function getCreditList() {
    const params = {};
    Sdk.getCreditList(
      getCreditListSuccess,
      getCreditListFailed,
      updateProgress,
      params
    );
  }

  function getCreditListSuccess(response) {
    setCreditdataList(response.credit_list.basic);
  }
  console.log("cred", creditDataList);

  function getCreditListFailed(errorList) {
    console.error("Error fetching credit list:", errorList);
  }

  // Update loading state
  function updateProgress(status) {
    setLoading(status);
  }

  function getCreditDetails() {
    var params = {};
    Sdk.getCreditDetails(
      getCreditDetailsSuccess,
      getCreditDetailsFailed,
      updateProgress,
      params
    );
  }

  function getCreditDetailsSuccess(response) {
    setCredit(response);
  }

  console.log(" cred credit", credit);

  function getCreditDetailsFailed(errorList) {
    console.error("Error fetching credit details:", errorList);
  }

  //  ------ Credit Buys -------

  const onCreditPurchaseSuccess = () => {
    getCreditDetails();
  };

  async function createCreditOrder(c_id) {
    const params = {
      c_id: c_id,
      redirect_url: "getreelax://payment/success",
    };

    Sdk.postCreditOrder(
      createCreditOrderSuccess,
      createCreditOrderFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }


  async function createCreditOrderSuccess(response) {
    console.log("pay ", response);
    if (response) {
      try {
        await createOrder(response?.credit_order_data);
        onCreditPurchaseSuccess();
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Payment failed", { variant: "error" });
        setModalOpen(false);
      }
    }
  }

  const createOrder = (order_data) => {
    return new Promise((resolve, reject) => {
      const options = {
        key: order_data.payment_gateway_id,
        order_id: order_data?.pg_order_id,
        amount: order_data?.amount,
        currency: "INR",
        handler: function (response) {
          // console.log("Payment Success", response);
          enqueueSnackbar("Credits added to you account successfully", { variant: "success" });
          closeSendProposalModal();

          resolve(response);
        },
        modal: {
          ondismiss: function () {
            reject(new Error("Payment was dismissed by the user"));
          },
        },

        theme: {
          color: "#F37254",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      rzp.on("payment.failed", function (response) {
        // Razorpay failure callback
        enqueueSnackbar("Payment Failed", { variant: "error" });
        reject(new Error(response.error.description));
      });
    });
  };

  function createCreditOrderFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "), {
      variant: "error",
    });
  }

  console.log("cred Setcredit", creditId);
  
  // ------ UI Render -------

  function AllCreditList() {
    return (
  <>
                 
                {creditDataList &&
                  creditDataList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => setCreditId(item.c_id)}
                        style={{

                          padding: "10px",
                          marginTop: "8px",
                          backgroundColor: "#fff",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "4px",
                          border:
                            creditId === item.c_id
                              ? "1.4px solid #090A14"
                              : "1px solid #CBCBCB",
                          cursor: "pointer",
                        }}
                      >
                         
                        <div>
                  
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000",
                            }}
                          >
                             
                            {item.c_quantity + " Credits"} 
                          </span> 
                        </div> 
                        <div style={{ marginLeft: "6px" }}>
                           
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000",
                            }}
                          >
                             
                            {"₹" +
                              (item.c_quantity * item.c_value).toFixed(0) +
                              " (₹" +
                              item.c_value +
                              "/credit)"} 
                          </span>
                        </div>
                      </div>
                    );
                  })} 
              </>
          
    );
  }

  function renderCreditScreen() {
    return (
      <>
        {creditDataList && (
          <>
            <div
              className=""
              style={{
                backgroundColor: "#f0f3f9",
                minHeight: "41vh",
                overflowY: "hidden",
                padding: "20px 20px 0px 20px",
              }}
            >
              <div>
                <CustomText
                  h5
                  text={"Buy Credit"}
                  className="text-15 font-500 text-align"
                />

                <CustomText
                  p
                  text={`You have ${credit?.credit_data} credits`}
                  className="text-13 font-500 text-align m-t-16"
                />
                <div
                  onClick={() => navigate("/credit-history")}
                  style={{marginBottom : "30px"}}
                  className="m-t-8 cursor-pointer"
                >
                  <CustomText
                    p
                    text={"Credit History"}
                    className="text-13 font-600 text-align"
                  />
                </div>
              </div>

              <div className="credit-list">{AllCreditList()}</div>
              <div>
                <CustomText
                p
                text = {"Taxes will be applicable*"} 
                className = "text-14 font-500 text-align m-t-8" />
              </div>
              
            </div>
            <div className="flex justify-center">
              <PrimaryCta
                onClick={() => createCreditOrder(creditId)}
                className="m-t-12 btn-bottom-detail-page"
                text={"Buy Credits"}
                isLoading={effectiveLoad}
              />
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <Popup
          isOpen={isModalOpen}
          onClose={closeSendProposalModal}
          headerText={
             "Buy Credits"
          }
          width="50%"
          showFooter={false}
          bottomSheetMaxHeight={0.7}>
        <div>
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            renderCreditScreen()
          )}
        </div>
      </Popup>
      )
}

export default CreditBottom
