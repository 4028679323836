import React, { useCallback, useEffect, useState } from "react";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { useNavigate } from "react-router-dom";
import { CustomText, PrimaryCta } from "../../common/core_component";
import { Loader } from "../Loader";
import Utils from "../../common/utils";
import ReactPullToRefresh from "react-pull-to-refresh";
import Footer from "../common/Footer";
import Header from "../header";

function BankDetails() {
  const [bankDetails, setBankDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true)
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   setLoading(false)
  // }, []);

  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();

  function updateProgress(status) {
    setLoading(status);
  }
  useEffect(() => {
    getBankDetails();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);

  function getBankDetails() {
    var params = {};
    Sdk.getBankDetails(
      getBankDetailsSuccess,
      getBankDetailsFailed,
      updateProgress,
      params
    );
  }
  function getBankDetailsSuccess(response) {
    setBankDetails(response.bank_data);
    // props.route.params.refreshUserData();
    // setAppLoaded(true);
  }
  console.log("bd ", bankDetails);

  function getBankDetailsFailed(error) {
    // setAppLoaded(true);
    console.error("bank err", error);
  }
  function updateProgress(status) {
    setLoading(status);
  }

  function renderBank() {
    return (
      <>
        {bankDetails ? (
          <div>
            <div
              className="flex-column  white-card-bg flex"
              style={{ gap: "10px", margin: "20px", padding: "15px" }}>
              <div className="flex justify-between ">
                <CustomText
                  p
                  text={"Account Holder Name"}
                  className="text-13"
                />
                <CustomText
                  p
                  text={bankDetails?.account_holder_name || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
              <div className="flex justify-between ">
                <CustomText p text={"Bank Name"} className="text-13" />
                <CustomText
                  p
                  text={bankDetails?.bank_name || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>

              <div className="flex justify-between ">
                <CustomText p text={"IFSC Code"} className="text-13" />
                <CustomText
                  p
                  text={bankDetails?.ifsc_code || "N/A"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
              <div className="flex justify-between ">
                <CustomText p text={"Account Number"} className="text-13" />
                <CustomText
                  p
                  text={bankDetails?.account_number || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div
              className="align-center justify-center flex-column "
              style={{ height: "84vh" }}>
              <CustomText
                p
                text={"No Bank Account Found"}
                className="text-color-p"
              />
              <PrimaryCta
                className="custom-button m-t-12"
                onClick={() => navigate("/add-bank")}
                text="Add Bank Account"
              />
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="main-container">
      <div className="inner-main-container">
        <div className=" flex justiify-center gap-10"></div>
        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div className="" style={{}}>
            <div>{renderBank()}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BankDetails;
