import React, { useCallback, useEffect, useState } from "react";
import Footer from "../common/Footer";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import { Loader } from "../Loader";
import Inreview from "./assignment-update/Inreview";
import In_Progress from "./assignment-update/In_progess";
import Completed from "./assignment-update/Completed";
import ReactPullToRefresh from "react-pull-to-refresh";
import Utils from "../../common/utils";
import { useLocation, useNavigate } from "react-router-dom";
import svgs from "../../assets/icons/svgs";
import LoginOtpScreen from "../common/LoginOtpScreen";
import Header from "../header";

function AllContract() {
  const [loading, setLoading] = useState();
  const [isLoginOtpSheet, setisLoginOtpSheet] = useState(false);
  const [activeTab, setActiveTab] = useState("new");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [next, setNext] = useState(10);
  const [start, setStart] = useState(0);
  const limit = 10;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);

  const openLoginOtp_Sheet = () => {
    setisLoginOtpSheet(true);
  };

  const handleNewClick = () => {
    setActiveTab("new");
  };

  const handleInReviewClick = () => {
    setActiveTab("in-review");
  };
  const handleInClosedClick = () => {
    setActiveTab("close");
  };

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const location = useLocation();

  useEffect(() => {
    if (location.state?.SendTab) {
      setActiveTab(location.state.SendTab);
    }
  }, [location.state]);

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("influn", influencer_data.influencer_id);

  const renderList = () => {
    if (activeTab === "new") {
      return <Inreview />;
    } else if (activeTab === "in-review") {
      return <In_Progress />;
    } else if (activeTab === "close") {
      return <Completed />;
    }
  };

  const navigate = useNavigate();

  if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
    return (
      <div className="main-container">
        <div className="inner-main-container">
          <div className="justify-center flex-column" style={{ marginTop: 20 }}>
            <div className="fallback">
              <FallBack
                icon={svgs.reelaxLogo}
                heading="Please Login"
                title="For access Contracts please login to Reelax"
              />
              <div className="container page-center m-b-25">
                <PrimaryCta
                  className={"custom-button"}
                  onClick={() => setisLoginOtpSheet(true)}
                  text={"Login"}
                />
              </div>
            </div>
          </div>
        </div>
        <LoginOtpScreen
          isModalOpen={isLoginOtpSheet}
          setModalOpen={setisLoginOtpSheet}
        />
      </div>
    );
  }

  return (
    <div className="main-container">
      <div className="inner-main-container">
        <div className="popular-invited" style={{}}>
          <div
            onClick={() => setActiveTab("new")}
            className={`tab ${activeTab === "new" ? "active-tab" : ""}`}>
            <CustomText p text={"NEW"} className="small-txt-pop-invited" />
          </div>
          <div
            onClick={() => setActiveTab("in-review")}
            className={`tab ${activeTab === "in-review" ? "active-tab" : ""}`}>
            <CustomText
              p
              text={"IN PROGRESS"}
              className="small-txt-pop-invited"
            />
          </div>
          <div
            onClick={() => setActiveTab("close")}
            className={`tab ${activeTab === "close" ? "active-tab" : ""}`}>
            <CustomText p text={"CLOSED"} className="small-txt-pop-invited" />
          </div>
        </div>
        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div style={{ paddingTop: "83px" }}>
            <div className="m-t-20" style={{ paddingBottom: "65px" }}>
              {renderList()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllContract;
