import {useState, useEffect} from 'react';

import {
  Routes, //replaces "Switch" used till v5
  Route,
  useParams,
} from "react-router-dom";

import { useSelector } from "react-redux";

import WebStorage from "../common/storage/storage";
import * as WebStorageKey from "../common/storage/keys";
import Utils from "../common/utils";

import ConsoleScreen from "../screens/ConsoleScreen";
import LoginScreen from '../screens/LoginScreen';
import OtpScreen from '../screens/OtpScreen';
import InfluencerDataScreen from '../screens/InfluencerDataScreen';
import AboutWorkData from '../screens/AboutWorkData';
import ThankYouScreen from '../screens/ThankYouScreen';
import HomeScreen from '../screens/HomeScreen';
import CampaignDetails from '../screens/campaign/CampaignDetails';
import AllCampaignShow from '../screens/campaign/AllCampaignShow';
import ConnectMedia from '../screens/Profile/ConnectMedia';
import BidSucess from '../screens/campaign/BidSucess';
import BidSuccess from '../screens/campaign/BidSucess';
import ProfilePage from '../screens/Profile/ProfilePage';
import AddAddress from '../screens/Profile/AddAddress';
import KycDetails from '../screens/Profile/KycDetails';
import PortFolioList from '../screens/Profile/PortFolioList';
import BankDetails from '../screens/Profile/BankDetails';
import EditProfession from '../screens/Profile/EditProfession';
import EditProfile from '../screens/Profile/EditProfile';
import SettingScreen from '../screens/Profile/SettingScreen';
import NotificationsScreen from '../screens/common/NotificationsScreen';
import AllBids from '../screens/bidpages/AllBids';
import AllContract from '../screens/Contracts/AllContract';
import AddressPage from '../screens/Profile/AddressPage';
import AddBankDetails from '../screens/Profile/AddBankDetails';
import ContractDetails from '../screens/Contracts/assignment-update/ContractDetails';
import HelpAndSupport from '../screens/settingSubpage/HelpAndSupport';
import Earning from '../screens/settingSubpage/Earning';
import PayoutHistory from '../screens/settingSubpage/PayoutHistory';
import Transaction from '../screens/settingSubpage/Transaction';
import CreditHistory from '../screens/common/CreditHistory';
import CampaignByBrand from '../screens/campaign/CampaignByBrand';
import CampaignByCategory from '../screens/campaign/CampaignByCategory';
import AllBrand from '../screens/campaign/AllBrand';
import NotFound from '../screens/common/NotFound';
import ImageVideoFullscreen from '../screens/common/ImageVideoFullScreen';
import DetailPage from '../screens/Contracts/assignment-update/DetailPage';
import Add_your_Social_media from '../screens/Profile/Add_your_Social_media';
import Analytics from '../screens/Profile/analytics';
import InstagramAnalytics from '../screens/Profile/intagramAnalytics';


const RootNavigator = function (props) {

  return (
    <Routes>    
      <Route path="/" element={<HomeScreen/>} />
      {/* <Route path="/login" element={<LoginScreen />} />
      <Route path="/otp-screen" element={<OtpScreen />} /> */}
      <Route path="/influencer-data" element={<InfluencerDataScreen />} />
      <Route path="/about-work" element={<AboutWorkData />} />
      <Route path="/profile" element={<ProfilePage />} />
      {/* ------ Profile Screens ------ */}
      <Route path="/add-address" element={<AddAddress />} />
      <Route path="/address-page" element={<AddressPage />} />
      <Route path="/kyc-detail" element={<KycDetails />} />
      <Route path="/portfolio" element={<PortFolioList/>} />
      <Route path="/bank-detail" element={<BankDetails />} />
      <Route path="/add-bank" element={<AddBankDetails />} />
      <Route path="/social-channel" element={<Add_your_Social_media/>}/>
      <Route path="/profession" element={<EditProfession/>}/>
      <Route path="/edit-profile" element={<EditProfile/>}/>
      <Route path="/settings" element={<SettingScreen/>}/>
      <Route path="/help-support" element={<HelpAndSupport/>}/>
      <Route path="/earning" element={<Earning/>}/>
      <Route path="/payout-history" element={<PayoutHistory/>}/>
      <Route path="/transaction" element={<Transaction/>}/>
      <Route path="/youtube-analytics" element={<Analytics/>}/>
      <Route path="/instagram-analytics" element={<InstagramAnalytics/>}/>    

{/* --- profile Screen ended --- */}
<Route path="/image-video-fullscreen" element={<ImageVideoFullscreen />} />
      <Route path="/thankyou-screen" element={<ThankYouScreen />} />
      <Route path="/credit-history" element={<CreditHistory/>} />
      <Route path="*" element={<HomeScreen/>}/>
      <Route path="/campaign/:id" element={<CampaignDetails/>}/>
      <Route path="/bid-success" element={<BidSuccess/>}/>
      <Route path="/campaigns" element={<AllCampaignShow/>}/>
      <Route path="/Contract-details/:id" element={<ContractDetails/>}/>
      <Route path="/Details/:id" element={<DetailPage/>}/>    
      <Route path="/NotificationsScreen" element={<NotificationsScreen/>}/>
      <Route path="/social-media" element={<Add_your_Social_media/>}/>   
      <Route path="/bids" element={<AllBids/>}/>
      <Route path="/contracts" element={<AllContract/>}/>
      <Route path="/campaign-brand/:id" element={<CampaignByBrand/>}/>
      <Route path="/campaign-category/:id" element={<CampaignByCategory/>}/>
      <Route path="/all-brand" element={<AllBrand/>}/>   
    </Routes>
  );
};
export default RootNavigator;