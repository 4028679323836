import React, { useEffect, useRef, useState } from "react";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Action from "../../redux/actions";
import { setLocalUserData } from "../../common/storage/storage";
import Icon from "../../assets/icons.js";
import { CustomText, Popup, PrimaryCta } from "../../common/core_component";
import { Loader } from "../Loader";
import Utils from "../../common/utils";
import { BottomSheet } from "react-spring-bottom-sheet";
import svgs from "../../assets/icons/svgs.js";
import { LoginSocialGoogle } from "reactjs-social-login";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

function Add_your_Social_media() {
  const [loading, setLoading] = useState(false);
  const [isAppLoaded, setAppLoaded] = useState(false);
  const [btnLoader, setBtnLoader] = useState({});
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState({});
  const [socialUsername, setSocialUsername] = useState();
  const [platformDataPublic, setPlatformDataPublic] = useState();
  const [platformDataInfluencer, setPlatformDataInfluencer] = useState();
  const [platformData, setPlatformData] = useState();
  const [selectedPlatformId, setSelectedPlatformId] = useState();
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openRemoveInstaModal, setOpenRemoveInstaModal] = useState(false);
  const [openRemoveYoutubeModal, setOpenRemoveYoutubeModal] = useState(false);
  const [instagramData, setInstagramData] = useState(null);
  const [instagramListData, setInstagramListData] = useState(null);
  const [googleData, setGoogleData] = useState(null);
  const [platformDataLoading, setPlatformDataLoading] = useState(false);
  const [addPlatfromLoading, setAddPlatfromLoading] = useState(false);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [selectedPlatformIdAuto, setSelectedPlatformIdAuto] = useState(null);
  const [platformListData, setPlatformListData] = useState(null);
  const [platformAccessToken, setPlatformAccessToken] = useState(null);
  const [showIntroText, setShowIntroText] = useState(true);
  const [selectedPlatformRemoveId, setSelectedPlatformRemoveId] =
    useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [VerifyModalOpen, setVerifyModalOpen] = useState(false);
  const config = {
    issuer: "https://accounts.google.com",
    clientId:
      "812102543926-2678dtjli58h3gbohba5g6tmb1fhu2fu.apps.googleusercontent.com",
    redirectUrl: "com.reelax.influencer:/oauth2redirect/google",
    scopes: [
      "https://www.googleapis.com/auth/youtube.readonly,yt-analytics.readonly",
    ],

    serviceConfiguration: {
      authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
      tokenEndpoint: "https://oauth2.googleapis.com/token",
    },
  };
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });
  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (instagramData) {
      setShowIntroText(false);
    } else {
      setShowIntroText(true);
    }
  }, [instagramData]);
  const location = useLocation();

  function validateSocialMedia() {
    if (!socialUsername) {
      enqueueSnackbar("Please enter user name");
      return false;
    }
    return true;
  }

  function updateProgress(status) {
    setLoading(status);
  }

  const navigate = useNavigate();

  useEffect(() => {
    getPlatformListPublic();
  }, []);

  function validateSocialMedia() {
    if (!socialUsername) {
      enqueueSnackbar("Please enter user name");
      return false;
    }
    return true;
  }

  function getPlatformListPublic() {
    var params = {};
    Sdk.getPlatformListPublic(
      getPlatformListPublicSuccess,
      getPlatformListPublicFailed,
      updateProgress,
      params
    );
  }

  function getPlatformListPublicSuccess(response) {
    getPlatformListInfluencer();
    setPlatformDataPublic(response.platform_list);
  }

  function getPlatformListPublicFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getPlatformListInfluencer() {
    var params = {};
    Sdk.getPlatformListInfluencer(
      getPlatformListInfluencerSuccess,
      getPlatformListInfluencerFailed,
      updateProgress,
      params
    );
  }

  function getPlatformListInfluencerSuccess(response) {
    if (response?.platform_list.length > 0) {
      response?.platform_list?.forEach(function (platform_data) {
        if (platform_data?.["platform_id"] == "btll") {
          setInstagramData(platform_data);
        } else {
          setGoogleData(platform_data);
        }
      });
    }

    setAppLoaded(true);
    setPlatformDataInfluencer(response?.platform_list);

    refreshUserData();
  }

  function getPlatformListInfluencerFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function refreshUserData() {
    const params = {};

    Sdk.getInfluencerDataSelf(
      refreshUserDataSuccess,
      refreshUserDataFailed,
      () => {},
      params,
      reduxData.UserData.authToken
    );
  }

  function refreshUserDataSuccess(userData, authToken) {
    setLocalUserData(userData);
    if (userData) {
      const { influencer_deep_data } = userData;
      dispatch({
        type: Action.UPDATE_USER_DATA,
        payload: influencer_deep_data,
      });
    }
  }

  function refreshUserDataFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err["m"]).join(", "));
  }

  function updateInfluencerPlatform(platform) {
    var params = {
      influencer_id: influencer_data["influencer_id"],
      platform_data: platform,
    };
    Sdk.updateInfluencerPlatform(
      updateInfluencerPlatformSuccess,
      updateInfluencerPlatformFailed,
      updateProgress,
      params
    );
  }

  function updateInfluencerPlatformSuccess(api_response) {
    console.log("response Demo @@@@&&&&&", api_response);
  }

  function updateInfluencerPlatformFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function postAddPlatform(id, item) {
    if (!validateSocialMedia(socialUsername)) {
      return false;
    }
    var params = {
      platform_id: id,
      identifier_type: item.identifier_type,
      identifier_id: socialUsername,
    };
    Sdk.postAddPlatform(
      postAddPlatformSuccess,
      postAddPlatformfailed,
      (status) => setBtnLoader({ [id]: status }),
      params
    );
    setSocialUsername("");
  }

  function postAddPlatformSuccess(response) {
    if (response.success) {
      getPlatformListInfluencer();
    }
  }

  function postAddPlatformfailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postRemovePlatform(id) {
    var params = {
      platform_id: id,
    };
    Sdk.postRemovePlatform(
      (...args) => postRemovePlatformSuccess(id, ...args),

      postRemovePlatformfailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function postRemovePlatformSuccess(id, response) {
    if (response.success) {
      if (id == "btll") {
        console.log("remove success", id);
        getPlatformListInfluencer();
        setInstagramData(null);
        setShowIntroText(true);
        setOpenRemoveInstaModal(false);
        enqueueSnackbar(" Instagram Account Removed Successfully", {
          variant: "success",
        });
      } else {
        console.log("remove success", id);
        getPlatformListInfluencer();
        setGoogleData(null);
        setOpenRemoveYoutubeModal(false);
        enqueueSnackbar("Youtube Account Removed Successfully", {
          variant: "success",
        });
      }
    }
  }

  function postRemovePlatformfailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const fromState = location.state?.from;

  const openInstaRemoveModal = () => {
    setOpenRemoveInstaModal(true);
  };

  const openYoutubeRemoveModal = () => {
    setOpenRemoveYoutubeModal(true);
  };

  const closeInstaRemoveModal = () => {
    setOpenRemoveInstaModal(false);
  };

  const closeRemoveYoutubeModal = () => {
    setOpenRemoveYoutubeModal(false);
  };

  const closeSelectPlatformBottomSheet = () => {
    setBottomSheetVisible(false);
  };

  const openSelectPlatformBottomSheet = () => {
    setBottomSheetVisible(true);
  };

  const renderRemoveInstaSheet = () => (
    <Popup
      isOpen={openRemoveInstaModal}
      onClose={closeInstaRemoveModal}
      headerText={"Remove Instagram"}
      width="50%"
      footerButton1Text={"No"}
      footerButton1Action={closeInstaRemoveModal}
      footerButton2Text={"Yes"}
      footerButton2Action={() => postRemovePlatform("btll")}
      bottomSheetMaxHeight={0.7}
      isLoading={effectiveLoad}
    >
      <div>
        <div className="text-align">
          <CustomText
            p
            text={"Are you sure you want to remove your Instagram Account?"}
          />
        </div>
      </div>
    </Popup>
  );

  const renderRemoveYoutubeSheet = () => (
    <Popup
      isOpen={openRemoveYoutubeModal}
      onClose={closeRemoveYoutubeModal}
      headerText={"Remove Youtube"}
      width="50%"
      footerButton1Text={"No"}
      footerButton1Action={closeRemoveYoutubeModal}
      footerButton2Text={"Yes"}
      footerButton2Action={() => postRemovePlatform("nxjo")}
      bottomSheetMaxHeight={0.7}
      isLoading={effectiveLoad}
    >
      <div>
        <div className="text-align">
          <CustomText
            p
            text={"Are you sure you want to remove your Youtube Account?"}
          />
        </div>
      </div>
    </Popup>
  );

  async function onFacebookButtonClick(response) {
    try {
      if (response) {
        const responseData = response;

        const accessToken = responseData?.accessToken;

        console.log("Facebook Access Token:", accessToken);

        if (accessToken) {
          setPlatformDataLoading(true);
          setSelectedPlatformIdAuto("btll");
          setPlatformDataLoading(true);
          getPlatformBasicData(accessToken, "btll");

          console.log("facebook getplatformdata");
        }
      }
    } catch (error) {
      console.error("Error during Facebook login:", error.message);
    }
  }

  //  ------ youtube login ------

  const connectYouTube = async (response) => {
    try {
      console.log("YouTube Response:", response);
      const responseData = response?.data;

      console.log("youtube token response:", responseData);

      const credential = responseData?.access_token;
      console.log("YouTube Access Token:", credential);

      setSelectedPlatformIdAuto("nxjo");
      setPlatformDataLoading(true);
      getPlatformBasicData(credential, "nxjo");
    } catch (error) {
      console.error("Failed to authenticate or fetch YouTube data", error);
    }
  };

  function getPlatformBasicData(access_token, platform_id) {
    var params = {
      access_token: access_token,
      platform_id: platform_id,
    };

    Sdk.getPlatformBasicData(
      (...args) => getPlatformBasicDataSuccess(access_token, ...args),
      getPlatformBasicDataFailed,
      updateProgress,
      params
    );
  }

  function getPlatformBasicDataSuccess(access_token, response) {
    console.log("facebook getPlatformBasicDataSuccess", response);

    console.log("facebook  access token PlatformSuccess ", access_token);

    setPlatformAccessToken(access_token);
    openSelectPlatformBottomSheet();
    setPlatformListData(response.response);
    setPlatformDataLoading(false);
  }

  function getPlatformBasicDataFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
    setPlatformDataLoading(false);
  }

  function addSocialAccountAuto(raw_basic_insta_data, platform_id) {
    raw_basic_insta_data["accessToken"] = platformAccessToken;

    let params = {
      platform_id: platform_id,
      platform_data: JSON.stringify(raw_basic_insta_data),
    };

    Sdk.addSocialAccountAuto(
      (...args) => addSocialAccountAutoSuccess(platform_id, ...args),
      addSocialAccountAutoFailed,
      (status) => {
        setAddPlatfromLoading(status);
      },
      params
    );
  }

  function addSocialAccountAutoSuccess(platform_id, response) {
    let platform_name;
    if (selectedPlatformIdAuto == "btll") {
      platform_name = "Instagram";
    } else {
      platform_name = "Youtube";
    }

    enqueueSnackbar(
      `Your ${platform_name} Account has been linked successfully`,
      {
        variant: "success",
      }
    );
  }

  function addSocialAccountAutoFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
    setPlatformDataLoading(false);
  }

  function onClickHandlerBottomSheetAction(
    platform_data_auto,
    raw_basic_platform_data
  ) {
    if (selectedPlatformIdAuto == "btll") {
      setInstagramData(platform_data_auto);
    } else {
      setGoogleData(platform_data_auto);
    }

    // setBottomSheetVisible(false);
    closeSelectPlatformBottomSheet();

    addSocialAccountAuto(raw_basic_platform_data, selectedPlatformIdAuto);
  }

  function constructPlatformData(raw_basic_platform_data) {
    let platform_data_auto = {};

    if (Utils.isEmpty(raw_basic_platform_data)) {
      return platform_data_auto;
    }

    if (selectedPlatformIdAuto == "nxjo") {
      platform_data_auto = {
        content_count: raw_basic_platform_data?.["statistics"]?.videoCount,
        identifier_id: raw_basic_platform_data?.["snippet"]?.customUrl,
        followers: raw_basic_platform_data?.["statistics"]?.subscriberCount,
        following: "-",
        full_name: raw_basic_platform_data?.["snippet"]?.title,
        profile_pic:
          raw_basic_platform_data?.["snippet"]?.["thumbnails"]?.["default"]?.[
            "url"
          ],
      };
    } else if (selectedPlatformIdAuto == "btll") {
      platform_data_auto = {
        content_count: raw_basic_platform_data?.media_count,
        identifier_id: "@" + raw_basic_platform_data?.username,
        followers: raw_basic_platform_data?.followers_count,
        following: raw_basic_platform_data?.follows_count,
        full_name: raw_basic_platform_data?.name,
        profile_pic: raw_basic_platform_data?.profile_picture_url,
      };
    }
    return platform_data_auto;
  }

  function renderAutoSocialChannel() {
    return (
      <div style={{ gap: "5px", flexDirection: "column", display: "flex" }}>
        {!instagramData ? (
          <ReactFacebookLogin
            appId="1933698043682854"
            autoLoad={false}
            isMobile={false}
            redirectUri={
              process.env.ENV == "DEV"
                ? "https://dev.creators.getreelax.com/social-channel"
                : "https://creators.getreelax.com/social-channel"
            }
            fields="name,email,picture"
            scope="public_profile,pages_show_list,instagram_basic,instagram_manage_insights,business_management"
            callback={(response) => onFacebookButtonClick(response)}
            render={(renderProps) => (
              <div
                style={{
                  borderRadius: "4px",
                  border: "1px solid #CBCBCB",
                  marginBottom: "5px",
                  backgroundColor: "#FFF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!platformDataLoading) {
                    renderProps.onClick();
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 14px",
                  }}
                >
                  <div
                    className="m-r-10 flex"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <div className="justify-center">
                      <Icon
                        icon={Utils.socialMediaIcons("btll")}
                        height={20}
                        width={20}
                      />
                    </div>
                    <div className="justify-center">
                      <CustomText p text={Utils.socialMediaName("btll")} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        ) : (
          <div
            style={{
              borderRadius: "4px",
              border: "1px solid #CBCBCB",
              marginBottom: "5px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 14px",
                alignItems: "center",
                borderBottom: "1px solid #CBCBCB",
              }}
            >
              <div
                className="flex-row m-r-10"
                style={{ display: "flex", gap: "5px" }}
              >
                <div className="justify-center">
                  <Icon
                    icon={Utils.socialMediaIcons("btll")}
                    height={20}
                    width={20}
                  />
                </div>
                <div className="justify-center">
                  <CustomText p text={Utils.socialMediaName("btll")} />
                </div>
              </div>
              <div>
                <button
                  onClick={() => openInstaRemoveModal("btll")}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  <Icon icon={svgs.delete_icon} height={20} width={20} />
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                paddingBottom: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  paddingRight: "26px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={instagramData?.profile_pic}
                  alt="DP"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                  <CustomText
                    p
                    text={"@" + instagramData?.identifier_id || "-"}
                    className="text-12"
                  />
                  <CustomText
                    p
                    text={instagramData?.full_name || "-"}
                    className="text-13 "
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "13px",
                  }}
                >
                  <CustomText
                    p
                    text="FOLLOWERS"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={instagramData?.followers || "-"}
                    className="text-12"
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <CustomText
                    p
                    text="FOLLOWING"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={instagramData?.following || "-"}
                    className="text-12"
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <CustomText
                    p
                    text="TOTAL POSTS"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={instagramData?.content_count || "-"}
                    className="text-12"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!googleData ? (
          <div className="">
            <div
              style={{
                borderRadius: "4px",
                border: "1px solid #CBCBCB",
                marginBottom: "5px",
                backgroundColor: "#FFF",
                padding: "10px 14px",
                cursor: "pointer",
              }}
            >
              <LoginSocialGoogle
                client_id="812102543926-lrdp4mlsff3gee3atcae80gihqh5vcq9.apps.googleusercontent.com"
                scope="https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly"
                onResolve={(res) => {
                  connectYouTube(res);
                }}
                onReject={(err) => {
                  console.error("Google login failed:", err);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="flex-row m-r-10"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <div className="justify-center">
                      <Icon
                        icon={Utils.socialMediaIcons("nxjo")}
                        height={20}
                        width={20}
                      />
                    </div>
                    <div className="justify-center">
                      <CustomText p text={Utils.socialMediaName("nxjo")} />
                    </div>
                  </div>
                  {platformDataLoading && selectedPlatformIdAuto === "nxjo"}
                </div>
              </LoginSocialGoogle>
            </div>
          </div>
        ) : (
          <div
            style={{
              borderRadius: "4px",
              border: "1px solid #CBCBCB",
              marginBottom: "5px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 14px",
                alignItems: "center",
                borderBottom: "1px solid #CBCBCB",
              }}
            >
              <div
                className="flex-row m-r-10"
                style={{ display: "flex", gap: "5px" }}
              >
                <div className="">
                  <Icon
                    icon={Utils.socialMediaIcons("nxjo")}
                    height={20}
                    width={20}
                  />
                </div>
                <div className="justify-center">
                  <CustomText p text={Utils.socialMediaName("nxjo")} />
                </div>
              </div>
              <div>
                <button
                  onClick={() => openYoutubeRemoveModal("nxjo")}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  <Icon icon={svgs.delete_icon} height={20} width={20} />
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                paddingBottom: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                cursor: "pointer",
              }}
              // onClick={() => navigate("/youtube-analytics")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  paddingRight: "26px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={googleData?.profile_pic}
                  alt="DP"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                />

                <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                  <CustomText
                    p
                    text={googleData?.identifier_id || "-"}
                    className="text-12"
                  />
                  <CustomText
                    p
                    text={googleData?.full_name || "-"}
                    className="text-13 "
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "13px",
                  }}
                >
                  <CustomText
                    p
                    text="FOLLOWERS"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={googleData?.followers || "-"}
                    className="text-12"
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <CustomText
                    p
                    text="FOLLOWING"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={googleData?.following || "-"}
                    className="text-12"
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <CustomText
                    p
                    text="TOTAL POSTS"
                    className="text-12 text-align-right"
                  />
                  <CustomText
                    p
                    text={googleData?.content_count || "-"}
                    className="text-12"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderBottomSheetPlatformData(
    platform_data_auto,
    raw_basic_platform_data
  ) {
    return (
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
          onClick={() =>
            onClickHandlerBottomSheetAction(
              platform_data_auto,
              raw_basic_platform_data
            )
          }
          disabled={addPlatfromLoading}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingRight: "26px",
              alignItems: "center",
            }}
          >
            <img
              src={platform_data_auto.profile_pic}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
              }}
            />
            <div style={{ marginLeft: "10px", marginTop: "3px" }}>
              <CustomText
                p
                text={platform_data_auto.identifier_id}
                className="text-12"
              />
              <CustomText
                p
                text={platform_data_auto.full_name}
                className="text-13 "
              />
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: "6px",
                display: "flex",
                flexDirection: "row",
                gap: "13px",
              }}
            >
              <CustomText
                p
                text="FOLLOWERS"
                className="text-12 text-align-right"
              />
              <CustomText
                p
                text={platform_data_auto.followers}
                className="text-12"
              />
            </div>
            <div
              style={{
                marginTop: "6px",
                display: "flex",
                flexDirection: "row",
                gap: "15px",
              }}
            >
              <CustomText
                p
                text="FOLLOWING"
                className="text-12 text-align-right"
              />
              <CustomText
                p
                text={platform_data_auto.following}
                className="text-12"
              />
            </div>
            <div
              style={{
                marginTop: "6px",
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <CustomText
                p
                text="TOTAL POSTS"
                className="text-12 text-align-right"
              />
              <CustomText
                p
                text={platform_data_auto.content_count}
                className="text-12"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderPlatformBottomSheet = () => (
    <BottomSheet
      open={bottomSheetVisible}
      onDismiss={closeSelectPlatformBottomSheet}
      snapPoints={({ maxHeight }) => [0.82 * maxHeight, maxHeight]}
      style={{ overflowY: "auto", zIndex: 9999, position: "absolute" }}
    >
      <div style={{ maxWidth: "786px", margin: "0 auto" }}>
        <div>
          <CustomText
            p
            text={"Select One Platform"}
            className="text-align-center text-16 m-t-b-10"
          />
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          {platformListData && platformListData.length > 0 ? (
            platformListData.map(function (data) {
              return renderBottomSheetPlatformData(
                constructPlatformData(data),
                data
              );
            })
          ) : (
            <div>
              <CustomText
                p
                text={"No Platform Found"}
                className="text-align-center error text-16 m-t-40"
              />
            </div>
          )}
        </div>
      </div>
    </BottomSheet>
  );

  const goBackNavigate = () => {
    if (fromState) {
      navigate(-1);
    } else {
      navigate("/profile");
    }
  };

  return (
    <>
      <div className="main-container">
        <div className="inner-main-container">
          <div style={{ display: "flex", gap: 10 }}></div>
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="container">
              <div
                className="p-t-10 flex flex-column"
                style={{ gap: "5px", marginTop: "10px" }}
              >
                {renderAutoSocialChannel()}
              </div>
              {showIntroText && (
                <div className="steps-container">
                  <h2>Steps to connect your Instagram Account</h2>

                  <div className="step">
                    <h3>Step 1</h3>
                    <p>
                      Make sure you have a professional business or creator
                      Instagram account
                    </p>
                  </div>

                  <div className="step">
                    <h3>Step 2</h3>
                    <p>
                      Make sure to connect a Facebook page to your Instagram
                      account
                    </p>
                  </div>

                  <div className="step">
                    <h3>Step 3</h3>
                    <p>
                      You can now connect your Instagram account by tapping on
                      the Instagram button, this will require you to login to
                      your Facebook account and give us the required
                      permissions.
                    </p>
                  </div>
                  <div className="" style={{marginTop:20,fontSize:16,color:"#5a5b62"}}>
                    <p>
                    The Reelax Social Channel connection process is highly secure. Reelax has partnered with Meta and Google to ensure a robust and safe connection. Reach out to Help and Support if you face any trouble. 
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {openInstaRemoveModal && renderRemoveInstaSheet()}
          {openRemoveYoutubeModal && renderRemoveYoutubeSheet()}

          {bottomSheetVisible && renderPlatformBottomSheet()}
        </div>
      </div>
    </>
  );
}
export default Add_your_Social_media;
