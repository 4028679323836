import { useState, useEffect } from "react";
import { CustomText } from "../../common/core_component";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Loader } from "../Loader";

const Analytics = () => {
  const [instagramData, setInstagramData] = useState([]);
  const [loading,setLoading]=useState(false)
  const [data, setData] = useState({ topLocations: [] });

  const reduxData = useSelector((state) => ({
    UserData: state.UserDataReducer,
  }));

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (influencer_data["influencer_id"]) {
      getInfluencerDemographics();
    }
  }, [influencer_data["influencer_id"]]); // Ensure influencer_id is available before calling API
 
  function updateProgress(status){
   setLoading(status)
  }
  function getInfluencerDemographics() {
    let params = {
      influencer_id: influencer_data["influencer_id"],
      platform_id: "btll",
      breakdown: "city",
      access_token:
        "EAAbesCjZCICYBO9ZCelGhKpcjF8PQbpqekpE4FQYEocZBs9ZA8zZBYmNA4qfZAlmkUGjZCJOhR6agi2fhQPzKJTjZA5hDr5acMY3sQyif9RUd09JMiaxDVKJjwVOxijfeojqYxJNUzCvKmXzrI9LGZCAGyi7ni0ZBZAScg7YYXeqwJ2FO9T4ijeomvZBR6wScDqZC8zC8yPgesgy5idPFKxmi5bzNWtvOw43hkQ34HgZDZD",
    };

    Sdk.getInfluencerDemographics(
      getInfluencerDemographicsSuccess,
      getInfluencerDemographicsFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDemographicsSuccess(response) {
    setInstagramData(
      response?.demography_data?.city_demography?.total_value?.breakdowns?.[0]?.results
    );
    setLoading(false)
  }
  console.log("instagramData", instagramData);

  function getInfluencerDemographicsFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  return (
    <div className="main-container">
      
      <div className="inner-main-container">
      {loading ? (
                <div className="loader-mid">
                  <Loader />
                </div>
              ) : (
        <div>
        <CustomText p text="Instagram Analytics" className="section-title" />

        <div className="analytics-section">
          <CustomText p text="Top Locations" className="section-title" />
          {instagramData?.length > 0 ? (
            instagramData?.map((loc, index) => (
              <div key={index} className="progress-container">
                <div className="label">
                  <CustomText p text={loc.dimension_values} />
                  <CustomText p text={`${loc.value}%`} />
                </div>
                <div className="progress-bar-analytics">
                  <div
                    className="progress-fill"
                    style={{ width: `${loc.value}%` }}
                  ></div>
                </div>
              </div>
            ))
          ) : (
            <CustomText p text="No data available" />
          )}
        </div>
        </div>
        )}
      </div>       
    </div>
  );
};

export default Analytics;
