import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import svgs from "../../assets/icons/svgs";
import Icon from "../../assets/icons";
import { CustomText, FallBack } from "../../common/core_component";
import Sdk from "../../common/sdk/influencerSdk";
import { useSelector } from "react-redux";
import { Loader } from "../Loader";
import moment from "moment/moment";
import ReactPullToRefresh from "react-pull-to-refresh";
import Footer from "../common/Footer";
import Header from "../header";

function Earning() {
  const [payouts, setPayout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balanceData, setBalanceData] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true)
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   setLoading(false)
  // }, []);

  const reduxData = useSelector((state) => ({
    UserData: state.UserDataReducer,
  }));

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    getBalance();
  }, []);

  function getBalance() {
    var params = {};
    Sdk.getBalance(getBalanceSuccess, getBalanceFailed, updateProgress, params);
  }

  function getBalanceSuccess(response) {
    setBalanceData({
      current_balance: response.current_balance,
      total_balance: response.total_balance,
    });
    getPayoutsList();
  }

  function getBalanceFailed(error) {
    console.error("get Balance Failed", error);
  }

  function getPayoutsList() {
    var params = {
      status: "PENDING",
    };
    Sdk.getPayoutsList(
      getPayoutsListSuccess,
      getPayoutsListFailed,
      updateProgress,
      params
    );
  }

  function getPayoutsListSuccess(response) {
    setPayout(response.payout_data);
  }

  function getPayoutsListFailed(error) {
    console.error("get Payout List Failed", error);
  }
  console.log("out", payouts);
  console.log("bal", balanceData);

  function updateProgress(status) {
    setLoading(status);
  }

  const navigate = useNavigate();

  function renderEarningsCard() {
    if (!balanceData) return null;
    return (
      <>
        <div className="white-card-bg flex flex-column justify-center">
          <div className="m-t-15 m-b-8">
            <CustomText
              p
              text={`Balance: Rs ${balanceData?.current_balance}`}
              className="text-rem text-color-p text-align"
            />
            <CustomText
              p
              text={`Lifetime Earning: Rs ${balanceData?.total_balance}`}
              className="text-14 m-t-12 text-align text-color-p"
            />
          </div>
        </div>
      </>
    );
  }

  function renderPayoutDetails() {
    if (!payouts || payouts.length === 0) {
      return (
        <div className="fallback p-t-12">
          <FallBack
            icon={svgs.folder}
            heading="No Payout Founds"
            title="No upcoming Payouts"
          />
        </div>
      );
    }
    return (
      <>
        <div className="white-card-bg m-t-8 flex flex-column justify-center">
          {payouts.map((payout, index) => (
            <React.Fragment key={index}>
              <div className="flex justify-between m-t-8 m-b-8">
                <div className="flex flex-column">
                  <CustomText
                    p
                    text={`Task Payment scheduled at ${
                      moment.unix(payout?.date_scheduled).format("DD MMM") ||
                      "N/A"
                    }`}
                    className="text-12"
                  />
                  <CustomText
                    p
                    text={`Contract Id: #${
                      payout?.assignment_id?.toUpperCase() || "N/A"
                    }`}
                    className="text-13"
                  />
                </div>

                <div>
                  <CustomText
                    p
                    text={`Rs. ${payout?.payout_amount || "-"}`}
                    className="green text-align text-13 m-t-4"
                  />
                </div>
              </div>
              {index !== payout.length - 1 && <hr className="dashed-line" />}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="main-container">
      <div className="inner-main-container">
        <div className="flex gap-10" style={{}}></div>

        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div className="" style={{ paddingTop: 20 }}>
            <div className="container">
              {renderEarningsCard()}
              <div className="flex justify-between ">
                <CustomText p text={"Upcoming payouts"} />
                <div
                  onClick={() => navigate("/payout-history")}
                  className="cursor-pointer">
                  <CustomText p text={"History"} />
                </div>
              </div>

              {renderPayoutDetails()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Earning;
