import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Sdk from '../../../common/sdk/influencerSdk'
import { Loader } from "../../Loader";
import Footer from "../../common/Footer";
import { CustomText, FallBack } from "../../../common/core_component";
import svgs from "../../../assets/icons/svgs";
import Brandlogo from '../../../assets/images/small-logo.png'
import Utils from "../../../common/utils";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";


function Completed() {
  const [loading, set_loading] = useState(false);
  const [assignmentList, setAssignmentList] = useState([]);
  const [next, setNext] = useState(10);
  const [start, setStart] = useState(0);
  const limit = 10; 

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { enqueueSnackbar } = useSnackbar();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  useEffect(() => {
    getAssignmentList(start , updateProgress);
  }, []);

  // Api -------
  function getAssignmentList(start , updateProgress) {
    var params = {
      // auth: authToken,
      status: "COMPLETED",
      start: start,
      limit: limit,
    };

    Sdk.getAssignmentList(
      getAssignmentListSuccess,
      getAssignmentListFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentListSuccess(api_response) {
    // setAppLoaded(true);
    console.log("new", api_response);
    // setAssignmentList(api_response?.assignment_list);

    if (start == 0) {
      setAssignmentList(api_response['assignment_list']);
    } else {
      setAssignmentList(...assignmentList, api_response['assignment_list']);
    }
    setNext(api_response.pagination_data?.next);
  }
  console.log("completed", assignmentList);

  function getAssignmentListFailed(error_list) {
    console.error(" error order list", error_list);
  }

  function updateProgress(status) {
    set_loading(status);
  }

  const navigate = useNavigate(); 
  //  ----- Render UI -----
  function renderCompltedAssginment() {
    const navigateTo = (id)=>{  
      navigate(`/Contract-details/${id}`);

    }
    const renderBudget = (reward_type, amount) => {
      if (reward_type == 'cash') {
        return Utils.formatMoney(amount);
      } else {
        return amount + ' Credits';
      }
    };

    return (
      assignmentList &&  assignmentList.length > 0 ?  (
        <div className="m-t-6 container">
          {/* <div className="flex justify-between">
            <CustomText h5 text={"CLosed Campaigns"} />
          </div> */}
          <div className="m-t-12">
            {assignmentList &&
              assignmentList.map((assignment, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(
                      assignment?.assignment_data?.assignment_id
                    )
                  }
                  className="card-bid-contract m-t-12"
                >
                  <div className="card-icon-bid-contract">
                    <img
                      src={
                        assignment.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              assignment.brand_data?.org_id,
                              assignment.brand_data?.image_id
                            )
                          : Brandlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign">
                    {/* 30 is another  parameter of function */}
                    <CustomText
                        h5
                        text = {Utils.truncateText(assignment?.campaign_data?.name,30)}
                         className= "text-13" />
                       
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Signed ${Utils.convertUnixToHumanDate(
                          assignment?.assignment_data?.date_added
                        )}`}
                        className = "text-12 error"
                      />
                    </div>

                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={renderBudget(assignment?.reward_data?.reward_type , assignment?.reward_data?.value?.amount)}
                        className="text-12 m-t-4"
                      />
                     
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          assignment?.campaign_data.type
                        )}
                        className="text-12 m-t-4"
                      />
                     
                    </div>
                   
                  </div>
                </div>
              ))}
          </div>
        </div>
      
      ) : (
        <div className="fallback p-t-12">
          <FallBack
            icon={svgs.folder}
            heading="No Contract Found"
            title="You don't have any contract in review"
          />
        </div>
      )
    );
  }
  


  return (
    <>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <>
          <div className="m-t-1">
          <InfiniteScroll
                  dataLength={assignmentList.length} 
                  next={()=> getAssignmentList(next, ()=>{})} 
                  hasMore={ next !== 0 && next !== null && next !== undefined} 
                  loader={""} 
                  scrollThreshold={0.9}
                >
                  
            {renderCompltedAssginment()}
                </InfiniteScroll>


          </div>
        </>
      )}
    </>
  );
}


export default Completed;
