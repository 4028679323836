import React, { useCallback, useEffect, useState } from "react";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import { Loader } from "../Loader";
import { CustomText, PrimaryCta } from "../../common/core_component";
import Select from "react-dropdown-select";
import Utils from "../../common/utils";
import Icon from "../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Svgs from "../../assets/icons/svgs";
import { useDispatch, useSelector } from "react-redux";
import * as Action from "../../redux/actions";
import { setLocalUserData } from "../../common/storage/storage";
import { CATEGORIES } from "../../common/data/influencer";
import ReactPullToRefresh from "react-pull-to-refresh";
import Footer from "../common/Footer";
import Header from "../header";

function EditProfession() {
  const [updateCategory, setUpdateCategory] = useState(null);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [profession, setProfession] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  const fromState = location.state?.from;
  console.log("from state", fromState);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
  }, []);

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  // ------- Profession Update apis -------

  function updateInfluencerData() {
    if (!formValidate()) {
      return false;
    }
    var params = {
      profession: JSON.stringify(profession),
    };

    Sdk.updateInfluencerData(
      updateInfluencerDataSuccess,
      updateInfluencerDataFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function updateInfluencerDataSuccess(response) {
    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: response.influencer_deep_data,
    });
    setLocalUserData(response); // Save data in async storage
    console.log("res", response);

    // if (props.route.params) {
    //   props.route.params.refreshUserData();
    // }
  }

  function updateInfluencerDataFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
    console.error("influ", errorList);
  }

  function updateInfluencerCategory() {
    if (!formValidate()) {
      return false;
    }
    let updated_category = [updateCategory.value];
    // console.log("cat", updated_category);

    var params = {
      influencer_id: influencer_data?.influencer_id,
      categories: JSON.stringify(updated_category),
    };

    Sdk.updateInfluencerCategory(
      updateInfluencerCategorySuccess,
      updateInfluencerCategoryFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function updateInfluencerCategorySuccess(api_response) {
    enqueueSnackbar("Profession Updated", { variant: "success" });
    if (fromState) {
      navigate(-1);
    } else {
      navigate("/profile");
    }
  }

  function updateInfluencerCategoryFailed(error_list) {
    enqueueSnackbar(error_list.map((item) => item.m).join(", "), {
      variant: "error",
    });
    console.error("upCat", error_list);
  }

  useEffect(() => {
    getCategory();
  }, []);

  function getCategory() {
    var params = {};
    Sdk.getCategory(
      getCategorySuccess,
      getCategoryFailed,
      updateProgress,
      params
    );
  }

  function getCategorySuccess(api_response) {
    // setAppLoaded(true);
    setCategoryList(Object.values(api_response?.category_list) || []);
  }
  // console.log("list", categoryList);
  function getCategoryFailed(error_list) {
    console.error("response error", error_list);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function formValidate() {
    const newErrors = {};

    if (!updateCategory) {
      newErrors.updateCategory = "Category selection is mandatory";
    }
    if (!profession) {
      newErrors.profession = "Profession selection is mandatory";
    }

    setError(newErrors);

    // Return true if no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  }

  const handleCategoryChange = (selectedOption) => {
    const val = selectedOption.map((option) => option.label);
    setUpdateCategory(selectedOption[0]);
  };

  const handleProfessionChange = (selectedOption) => {
    console.log("s options", selectedOption);
    if (selectedOption && selectedOption.length > 0) {
      const val = selectedOption.map((option) => option.label);
      setProfession(selectedOption[0].label);
    }
  };

  function renderProfessionBox() {
    return (
      <>
        <div>
          <div className="m-t-12">
            <CustomText p text="Category" className="m-b-5" />

            <Select
              isMulti={false}
              options={Object.values(CATEGORIES.CATEGORYS).map((category) => ({
                value: category.value,
                label: category.label,
              }))}
              value={updateCategory}
              onChange={(option) => handleCategoryChange(option)}
              placeholder="Select a Category"
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>
          {error.updateCategory && (
            <p className="error">{error.updateCategory}</p>
          )}

          {updateCategory && (
            <div className="m-t-12">
              <CustomText p text="Profession" className="m-b-5" />
              <Select
                isMulti={false}
                options={
                  CATEGORIES.PROFESSIONS[updateCategory?.value]
                    ? CATEGORIES.PROFESSIONS[updateCategory?.value].map(
                        (profession) => ({
                          value: profession.id,
                          label: profession.name,
                        })
                      )
                    : []
                }
                value={profession}
                onChange={(option) => handleProfessionChange(option)}
                placeholder="Select a Profession"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "1px solid #A6A5BB",
                    backgroundColor: "white",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    fontFamily: "Inter-Medium",
                  }),
                  option: (base) => ({
                    ...base,
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  }),
                }}
              />
              {error.profession && <p className="error">{error.profession}</p>}
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="main-container">
      <div className="inner-main-container">
        <div className=" flex -center gap-10"></div>

        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div
            className="container"
            style={
              {
                // paddingTop: "58px",
              }
            }>
            <div>{renderProfessionBox()}</div>

            <div className="">
              <PrimaryCta
                className={"btn-bottom-detail-page"}
                onClick={() => {
                  updateInfluencerData();
                  if (updateCategory) {
                    updateInfluencerCategory();
                  }
                }}
                isLoading={effectiveLoad}
                text={"Save"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditProfession;
