import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomText, FallBack, PrimaryCta } from "../common/core_component";
import reelaxLogo from "../assets/images/reelax-logo-dark-full.png";
import Sdk from "../common/sdk/influencerSdk";
import Utils from "../common/utils";
import Ourlogo from "../assets/images/small-logo.png";
import { Loader } from "./Loader";
import * as Action from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../assets/icons";
import svgs from "../assets/icons/svgs";
import Footer from "./common/Footer";
import CreditBottom from "./common/CreditBottom";
import { useSnackbar } from "notistack";
import ReactPullToRefresh from "react-pull-to-refresh";

const Header = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  const openCreditBottom = () => setModalOpen(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getPageName = () => {
    const path = location.pathname;
    if (path.startsWith("/campaign-brand/")) {
      return "Campaigns By Brand";
    }
    if (path.startsWith("/campaign-category/")) {
      return "Campaigns By Category";
    }
    if (path.startsWith("/campaign/")) {
      return "Campaign Details";
    }
    switch (path) {
      case "/profile":
        return "Profile";
      case "/portfolio":
        return "My Portfolio";
      case "/profession":
        return "Edit Profession";
      case "/edit-profile":
        return "Edit Profile";
      case "/social-channel":
        return "Social Channels";
      case "/campaigns":
        return "Campaigns";
      case "/bids":
        return "Proposals";
      case "/contracts":
        return "Contracts";
      case "/bank-detail":
        return "Bank Details";
      case "/address-page":
        return "Address Details";
      case "/add-address":
        return "Add Address Details";
      case "/add-bank":
        return "Add Bank Details";
      case "/kyc-detail":
        return "My Kyc";
      case "/settings":
        return "Settings";
      case "/earning":
        return "My Earnings";
      case "/payout-history":
        return "Payout History";
      case "/transaction":
        return "Transaction";
      case "/help-support":
        return "Help & Support";
      case "/about-work":
        return "About Work";
      case"/credit-history":
      return "Credit History";
      default:
        return "";
    }
  };

  return (
    <div className="home-header-fixed">
      {influencer_data?.influencer_id ? (
        <div className="header-section">
          {<div>{getPageName()}</div>}
          <div className="bg-credits cursor-pointer" onClick={openCreditBottom}>
            <Icon icon={svgs.primeIcon} width={27} height={27} />
          </div>
        </div>
      ) : (
        <div className="header-section">
          {isMobile ? <div>{getPageName()}</div> : <div></div>}
          <div className="bg-credits cursor-pointer">
            <Icon icon={svgs.primeIcon} width={27} height={27} />
          </div>
        </div>
      )}
      {/* Render CreditBottom component and pass isModalOpen & setModalOpen */}
      <CreditBottom isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Header;
