import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import { Loader } from "../Loader";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";

function PortFolioList() {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [portfolioList, setPortfolioList] = useState(false);
  const [isMobile,setIsMobile]=useState(window.innerWidth<=768)
  const { enqueueSnackbar } = useSnackbar();

  const downloadAppNavigatorBTN = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.reelax.influencer";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store
      window.location.href =
        "https://apps.apple.com/in/app/reelax-influencer-app/id6464481879";
    } else {
      enqueueSnackbar("Unsupported platform. Please visit on iOS or Android.");
    }
  };

  useEffect(() => {
    // getPortfolioListInfluencer();
  }, []);

  useEffect(
    ()=>{
     const handleResize=()=>setIsMobile(window.innerWidth);
     window.addEventListener("resize",handleResize)
    },[]
  )

  function getPortfolioListInfluencer() {
    var params = {};
    Sdk.getPortfolioListInfluencer(
      getPortfolioListInfluencerSuccess,
      getPortfolioListInfluencerFailed,
      updateProgress,
      params
    );
  }

  function getPortfolioListInfluencerSuccess(api_response) {
    console.log(
      "api_responseapi_responseapi_response",
      Object.values(api_response.portfolio_list)
    );
  }

  function getPortfolioListInfluencerFailed(error_list) {
    console.error("response error", error_list);
  }

  function updateProgress(status){
    setLoading(status);
  }

  return (
     <div className="main-container">
     <div className="inner-main-container">
      <div className=" flex justiify-center gap-10">
        {
          !isMobile && <CustomText p text={"My Portfolio"} />
        }
        
      </div>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div className="">
            <div className="fallback">
              <FallBack
                icon={svgs.reelaxLogo}
                heading="Download Reelax App"
                title="For Creating Portfolio Download our App"
              />

              <div
                className="container page-center"
                style={{
                  marginTop: "15px",
                  marginBottom: "23px",
                }}
              >
                <PrimaryCta
                  className={"custom-button"}
                  onClick={() => downloadAppNavigatorBTN()}
                  text={"Download Reelax App"}
                />
              </div>
            </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default PortFolioList;
