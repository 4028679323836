import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "../Loader";
import { CustomText, FallBack } from "../../common/core_component";
import svgs from "../../assets/icons/svgs";
import Icon from "../../assets/icons";
import Sdk from "../../common/sdk/influencerSdk";
import { useNavigate, useParams } from "react-router-dom";
import Utils from "../../common/utils";
import Ourlogo from "../../assets/images/small-logo.png";
import ReactPullToRefresh from "react-pull-to-refresh";

function CampaignByBrand() {
  const brand_id = useParams().id;
  console.log("id", brand_id);

  const [loading, set_loading] = useState(false);
  const [campaign_list, set_campaign_list] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  function updateProgress(status) {
    set_loading(status);
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    getCampaignList();
  }, []);

  function getCampaignList() {
    var params = {
      brand_id: brand_id,
    };
    Sdk.getCampaignList(
      getCampaignListSuccess,
      getCampaignListFailed,
      updateProgress,
      params
    );
  }

  function getCampaignListSuccess(api_response) {
    console.log("bdList", api_response);
    set_campaign_list(api_response.campaign_data);
  }
  console.log("list", campaign_list);

  function getCampaignListFailed(error_list) {
    console.error("response error", error_list);
  }

  function updateProgress(status) {
    set_loading(status);
  }

  // ------- RenderUi ------

  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);
        // console.log('Platform ID:', item.platform_data.platform_id);
        const icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        // console.log('Icon:', icon);
        return (
          <div key={index} className="card-icon-campaign">
            <Icon
              icon={Utils.socialMediaIcons(item.platform_data.platform_id)}
              height={15}
              width={15}
            />
          </div>
        );
      }
      return null;
    });
  }

  function renderCampaignList() {
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };
    return (
      <>
        {campaign_list && campaign_list.length > 0 ? (
          <div className="m-t-6 container">
            <div className="m-t-12 ">
              {campaign_list &&
                campaign_list.map((campagin, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigateTo(campagin.campaign_data.campaign_id)
                    }
                    className="card-campaign m-t-12">
                    <div className="card-icon-campaign">
                      <img
                        src={
                          campagin.brand_data?.image_id
                            ? Utils.generateBrandLogoUrl(
                                campagin.brand_data?.org_id,
                                campagin.brand_data?.image_id
                              )
                            : Ourlogo
                        }
                        alt="Brand Logo"
                      />
                    </div>
                    <div className="card-content-campaign">
                      <div className="card-title-campaign">
                        <CustomText
                          h5
                          text={Utils.truncateText(
                            campagin?.campaign_data?.name
                          )}
                          className="ellipsisText text-14"
                        />
                      </div>
                      <div className="card-timing-campaign">
                        <CustomText
                          p
                          text={`Ending ${Utils.convertUnixToHumanDate(
                            campagin.campaign_data.end_date
                          )}`}
                        />
                      </div>
                      <div className="card-detals-campaign">
                        <CustomText
                          p
                          text={Utils.getCampaignTypeText(
                            campagin.campaign_data.type
                          )}
                          className="small-txt"
                        />
                        <CustomText
                          p
                          text={
                            campagin.campaign_data.collab_type == "BARTER"
                              ? "CASHBACK"
                              : `${campagin.reward_data.type.toUpperCase()} UPTO ${Utils.formatMoney(
                                  campagin.campaign_data.max_budget.toFixed(0)
                                )}`
                          }
                          className="small-txt"
                        />
                      </div>
                      <div className="card-footer-campaign">
                        <div className="credits-campaign">
                          {/* .. ICONS .... */}
                          {
                            <span>
                              {renderSocialMediaIcon(
                                campagin.content_type_deep_data
                              )}
                            </span>
                          }

                          <span>
                            {!campagin.campaign_data.campaign_credits
                              ? "FREE"
                              : campagin.campaign_data.campaign_credits +
                                " CREDITS"}
                          </span>
                        </div>
                        <div className="proposals-campaign">
                          {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="fallback p-t-12">
            <FallBack
              icon={svgs.folder}
              heading="No Campaign Found"
              title="No campaigns found for this brand"
            />
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className="main-container">
        <div className="inner-main-container">
          <div
            className=" flex align-center gap-10"
            style={{ padding: "14px 0" }}></div>
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="">
              <div className="" style={{}}>
                {renderCampaignList()}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignByBrand;
